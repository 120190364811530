var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"wrap"},[_c('div',{staticClass:"get-btn-wrap"},[_c('div',{staticClass:"get-btn"},[(typeof _vm.data.status === 'number')?_c('el-dropdown',{style:({ backgroundColor: _vm.dropdownMenu[_vm.data.status].color }),attrs:{"split-button":"","type":"text"},on:{"command":_vm.commandHandle}},[_vm._v(" "+_vm._s(_vm.dropdownMenu[_vm.data.status].type)+" "),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.dropdownMenu.slice(1)),function(item,index){return _c('el-dropdown-item',{key:index,attrs:{"command":index}},[_vm._v(_vm._s(item.type))])}),1)],1):_vm._e()],1),_c('div',{staticClass:"comment",on:{"click":function($event){_vm.centerDialogVisible = true}}},[_vm._v("沟通备注")])]),_c('el-dialog',{attrs:{"title":"设置面试时间","visible":_vm.dialogVisible,"width":"30%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetime","default-value":_vm.newDate,"value-format":"timestamp","placeholder":"选择面试日期"},model:{value:(_vm.datetime),callback:function ($$v) {_vm.datetime=$$v},expression:"datetime"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false;
            _vm.datetime = '';}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.timeYes}},[_vm._v("确 定")])],1)],1),_c('div',{staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"img-wrap"},[(_vm.data.basic_gender === '男')?_c('img',{attrs:{"src":require("./../../assets/images/test/boy.png"),"alt":""}}):(_vm.data.basic_gender === '女')?_c('img',{attrs:{"src":require("./../../assets/images/test/girl.png"),"alt":""}}):_c('img',{staticClass:"weizhi",attrs:{"src":require("./../../assets/images/test/weizhi.png"),"alt":""}})]),_c('div',{staticClass:"all-base"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.data.basic_name))]),_c('div',{staticClass:"base"},[_c('span',[_vm._v(_vm._s(_vm.data.basic_gender))]),_c('i'),_c('span',[_vm._v(" "+_vm._s(_vm.data.basic_age == "保密" ? _vm.data.basic_age : _vm.data.basic_age + "岁")+" ")]),_c('i'),(_vm.data.resume_education_experience_list)?_c('span',[_vm._v(" "+_vm._s(_vm.data.resume_education_experience_list[0].education_background)+" ")]):_vm._e(),_c('span')]),_c('div',{staticClass:"expect"},[_c('p',[_vm._v(" 求职状态： "),_c('span',[_vm._v(_vm._s(_vm.data.career_progress > 0 ? _vm.list[_vm.data.career_progress - 1].text : "未选择"))])]),(_vm.data.basic_phone != '')?_c('p',[_vm._v(" 联系方式： "),_c('span',[_vm._v(_vm._s(_vm.data.basic_phone))])]):_vm._e(),_c('p',[_vm._v(" 期望职位： "),_vm._l((_vm.data.expect_position_name_list),function(item,index){return _c('span',{key:index,staticStyle:{"margin-right":"7px"}},[_vm._v(_vm._s(item))])})],2),_c('p',[_vm._v(" 期望地点： "),_vm._l((_vm.data.expect_position_place_list),function(item,index){return _c('span',{key:index,staticStyle:{"margin-right":"7px"}},[_vm._v(_vm._s(item))])})],2),(
              _vm.data.resume_expect_position_list &&
              _vm.data.resume_expect_position_list.length > 0
            )?_c('p',[_vm._v(" 期望薪资： "),_c('span',[_vm._v(" "+_vm._s(_vm.data.resume_expect_position_list[0].salary_min)+"-"+_vm._s(_vm.data.resume_expect_position_list[0].salary_max)+"元 ")])]):_vm._e(),_c('p',[_vm._v(" 浏览职位： "),_vm._l((_vm.data.clue_jd_name),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])})],2),_c('p',[_vm._v(" 婚否： "),_c('span',[_vm._v(_vm._s(_vm.data.marriage_status ? _vm.data.marriage_status : "未填写"))])]),_c('p',[_vm._v(" 预计到岗日期： "),_c('span',[_vm._v(_vm._s(_vm.data.arrival_time_str || "未填写"))])]),_c('p',[_vm._v(" 现住地： "),_c('span',[_vm._v(_vm._s(_vm.data.place_of_abode || "未填写"))])])])])]),(
        _vm.data.resume_work_experience_list &&
        _vm.data.resume_work_experience_list.length > 0 &&
        _vm.data.resume_work_experience_list[0].company_name != '' &&
        _vm.data.resume_work_experience_list[0].end_month != 0 &&
        _vm.data.resume_work_experience_list[0].position_name != ''
      )?_c('div',{staticClass:"work"},[_c('div',{staticClass:"title"},[_vm._v("工作经历")]),_vm._l((_vm.data.resume_work_experience_list),function(item,index){return _c('div',{key:index},[(
            item.company_name != '' &&
            item.end_month != 0 &&
            item.position_name != ''
          )?[(item.company_name != '')?_c('p',{staticClass:"company-name"},[_vm._v(" "+_vm._s(item.company_name)+" ")]):_vm._e(),(item.end_month != 0)?_c('p',[_vm._v(" 工作时间： "),_c('span',[_vm._v(" "+_vm._s(item.start_year)+"年"+_vm._s(item.start_month)+"月-"+_vm._s(item.end_year)+"年"+_vm._s(item.end_month)+"月 ")])]):_vm._e(),(item.upper != 0)?_c('p',[_vm._v(" 薪资水平： "),_c('span',[_vm._v(_vm._s(item.floor)+"-"+_vm._s(item.upper))])]):_vm._e(),(item.position_name != '')?_c('p',[_vm._v(" 在职职位： "),_c('span',[_vm._v(_vm._s(item.position_name))])]):_vm._e()]:_vm._e()],2)})],2):_vm._e(),(
        _vm.data.resume_education_experience_list.length > 0 &&
        _vm.data.resume_education_experience_list[0].school
      )?_c('div',{staticClass:"edu"},[_c('div',{staticClass:"title"},[_vm._v("教育经历")]),_vm._l((_vm.data.resume_education_experience_list),function(item,index){return _c('p',{key:index},[(item.school != '')?_c('span',[_vm._v(" "+_vm._s(item.school)+" "),_c('i')]):_vm._e(),(item.education_background != '')?_c('span',[_vm._v(_vm._s(item.education_background))]):_vm._e(),(item.major != '')?_c('span',[_c('i'),_vm._v(" "+_vm._s(item.major)+" ")]):_vm._e(),(item.end_month != 0)?_c('span',[_vm._v(" "+_vm._s(item.start_year)+"年"+_vm._s(item.start_month)+"月-"+_vm._s(item.end_year)+"年"+_vm._s(item.end_month)+"月 "),_c('i')]):_vm._e()])})],2):_vm._e(),(_vm.data.basic_self_evaluation != '')?_c('div',{staticClass:"self"},[_c('div',{staticClass:"title"},[_vm._v("自我评价")]),_c('div',{staticClass:"self-word"},[_vm._v(_vm._s(_vm.data.basic_self_evaluation))])]):_vm._e(),(_vm.data.remarks)?_c('div',{staticClass:"interview-comment"},[_c('div',{staticClass:"title"},[_vm._v("沟通备注")]),_c('div',{staticClass:"self-word"},[_vm._v(" "+_vm._s(_vm.data.remarks)+" ")])]):_vm._e()],1),_c('el-dialog',{attrs:{"title":"沟通备注","visible":_vm.centerDialogVisible,"width":"500px","center":""},on:{"update:visible":function($event){_vm.centerDialogVisible=$event}}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.str),expression:"str"}],staticClass:"area",domProps:{"value":(_vm.str)},on:{"input":function($event){if($event.target.composing){ return; }_vm.str=$event.target.value}}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.centerDialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.ResumeRemark}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }